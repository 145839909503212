<template>
  <div class="item">
    {{ item.days }}:
    <div class="item__hours">
      <div v-for="(h, hIdx) in item.hours" :key="`${item.days}_${hIdx}`">{{ h }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataItemInfoOpeningHoursItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;

  &__hours {
    margin-left: 8px;
    text-transform: uppercase;
  }
}
</style>
