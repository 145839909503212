<template>
  <div class="art-guide__events-group-item" :class="{ 'art-guide__item--expired': event.is_expired }">
    <div class="art-guide__events-group-item-image">
      <AppRouteLink class="art-guide__image-wrapper" :route-to="routeTo">
        <div class="art-guide__image" :style="`background-image: url(${media})`">
          <span class="art-guide__event-position">{{ event.position }}</span>
          <EntityCardLabel v-if="!event.is_expired && entityCardLabel" :label="entityCardLabel" />
        </div>
      </AppRouteLink>
    </div>

    <div class="art-guide__events-group-item-body">
      <AppRouteLink :class="{ 'art-guide__events-group-item-title-link': routeTo }" :route-to="routeTo">
        <div class="art-guide__events-group-item-title">{{ event.eventable.title }}</div>
      </AppRouteLink>
      <div>
        <AppSectionWithViewToggling v-if="event.eventable.description" title="Description">
          <template #body>
            {{ event.eventable.description }}
          </template>
        </AppSectionWithViewToggling>

        <AppSectionWithViewToggling v-if="isTimeHoursPresent" title="Time / hours">
          <template #body>
            <template v-if="isDatePresent">
              <p :class="{ 'mb-2': event.eventable.openingHourSeasons && event.eventable.openingHourSeasons.length }">
                <span class="mr-1">{{ datesLabel }}:</span>
                <span>{{ startDate }}</span>
                <span v-show="endDate !== startDate" class="ml-1">- {{ endDate }}</span>
              </p>
              <p v-if="!event.eventable.openingHourSeasons || !event.eventable.openingHourSeasons.length">
                <span class="mr-1">Time:</span>
                <span>{{ startTime }}</span>
                <span v-show="endTime !== startTime" class="ml-1">- {{ endTime }}</span>
              </p>
            </template>

            <div
              v-if="event.eventable.openingHourSeasons && event.eventable.openingHourSeasons.length"
              class="opening-hours"
            >
              <div
                v-for="(ohSeason, ohSeasonIndex) in event.eventable.openingHourSeasons"
                :key="`oh-season-${ohSeasonIndex}`"
                class="opening-hours-season"
              >
                <p v-show="ohSeason.title" class="opening-hours-season__title">{{ ohSeason.title }}:</p>
                <DataItemInfoOpeningHoursItem
                  v-for="(ohItem, ohIndex) in ohSeason.items"
                  :key="`oh-season-${ohSeasonIndex}-${ohIndex}`"
                  :item="ohItem"
                />
              </div>
            </div>
          </template>
        </AppSectionWithViewToggling>

        <AppSectionWithViewToggling
          v-if="getAddress || event.eventable.artSpace || event.eventable.accessType"
          title="Address / access"
        >
          <template #body>
            <p v-if="event.eventable.artSpace">{{ event.eventable.artSpace.title }}</p>
            <p v-if="getAddress">{{ getAddress }}</p>
            <ArtGuideEventAccessType v-if="!event.is_expired" :event="event" />
          </template>
        </AppSectionWithViewToggling>

        <AppSectionWithViewToggling body-without-horiz-padding title="My notes">
          <template #body>
            <div class="art-guide__notes">
              <textarea
                v-model="notes"
                class="art-guide__notes-field"
                placeholder="Type something ..."
                rows="3"
                @change="changeNotes"
                @keydown.prevent.enter="changeNotes"
                >{{ event.notes }}</textarea
              >
              <div v-show="notesChanging" class="art-guide__notes-loader">
                <AppLoader :border-width="2" :size="24" />
              </div>
            </div>
          </template>
        </AppSectionWithViewToggling>
      </div>
    </div>

    <div class="art-guide__events-group-item-extra-btns">
      <div class="art-guide__event-actions">
        <div class="art-guide__event-actions-item">
          <button
            v-if="isFavorite"
            class="art-guide__event-actions-favorite"
            :class="{ 'art-guide__event-actions-favorite--active': disableFavoriteButton }"
            :disabled="disableFavoriteButton"
            @click="changeFavoriteStatus"
          >
            <TheHeartIcon filled="filled" />
          </button>
          <button
            v-else
            class="art-guide__event-actions-favorite"
            :class="{ 'art-guide__event-actions-favorite--active': disableFavoriteButton }"
            :disabled="disableFavoriteButton"
            @click="changeFavoriteStatus"
          >
            <TheHeartIcon />
          </button>
        </div>

        <div class="art-guide__event-actions-item">
          <button v-if="!isDetaching" class="art-guide__event-actions-detach" @click="handleDetachEvent">
            <TheTrashIcon />
          </button>
          <AppLoader v-else :border-width="3" :margin="0" :size="22" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityCardLabel } from '@/helpers/entityCardLabel';
import { formatDate, formatTime } from '@/helpers/dateTimeHelper';

import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';
import changeArtGuideEventNotesMutation from '@/graphql/artGuide/ChangeArtGuideEventNotes.mutation.gql';

import AppSectionWithViewToggling from '@/components/partials/elements/AppSectionWithViewToggling';
import ArtGuideEventAccessType from '@/components/artguide/ArtGuideEventAccessType';
import AppRouteLink from '@/components/partials/AppRouteLink';
import TheTrashIcon from '@/components/icons/TheTrashIcon.vue';
import TheHeartIcon from '@/components/icons/TheHeartIcon.vue';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import DataItemInfoOpeningHoursItem from '@/components/partials/DataItemInfoOpeningHoursItem.vue';

export default {
  name: 'ArtGuideEventsGroupItem',
  components: {
    DataItemInfoOpeningHoursItem,
    EntityCardLabel,
    TheHeartIcon,
    TheTrashIcon,
    AppRouteLink,
    ArtGuideEventAccessType,
    AppSectionWithViewToggling,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    isDetaching: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      favoriteChanging: false,
      isFavorite: this.event.eventable.is_favorite_by_current_user,
      notes: this.event.notes,
      notesChanging: false,
    };
  },

  computed: {
    datesLabel() {
      return this.startDate !== this.endDate ? 'Dates' : 'Date';
    },
    disableFavoriteButton() {
      return this.favoriteChanging || this.isDetaching;
    },
    endDate() {
      return formatDate(this.event.eventable.latestEndDate);
    },
    endTime() {
      const endDate = this.event.eventable.latestEndDate;
      return this.isDatePresent && endDate ? formatTime(endDate) : '';
    },
    entityCardLabel() {
      return getEntityCardLabel(this.event.eventable);
    },
    getAddress() {
      return this.event.eventable.address;
    },
    isDatePresent() {
      return this.event.eventable.earliestStartDate;
    },
    isTimeHoursPresent() {
      return (
        this.isDatePresent ||
        (this.event.eventable.openingHourSeasons && this.event.eventable.openingHourSeasons.length)
      );
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.event.eventable);
    },
    routeTo() {
      return !this.event.is_expired ? getEntityRoute(this.event.eventable) : null;
    },
    startDate() {
      return formatDate(this.event.eventable.earliestStartDate);
    },
    startTime() {
      return formatTime(this.event.eventable.earliestStartDate);
    },
  },

  methods: {
    changeFavoriteStatus() {
      if (this.favoriteChanging) {
        return;
      }

      this.favoriteChanging = true;

      if (!this.isFavorite) {
        this.$apollo
          .mutate({
            mutation: addToUserFavoritesMutation,
            variables: {
              entities: [{ type: this.event.eventable.__typename, id: this.event.eventable.id }],
            },
          })
          .then(({ data }) => {
            this.favoriteChanging = false;
            this.isFavorite = data.addToUserFavorites;
            this.$emit('favorite-changed', this.event.position, this.isFavorite);
          })
          .catch(() => {
            this.favoriteChanging = false;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ type: this.event.eventable.__typename, id: this.event.eventable.id }],
          },
        })
        .then(({ data }) => {
          this.favoriteChanging = false;
          this.isFavorite = !data.deleteFromUserFavorites;
          this.$emit('favorite-changed', this.event.position, this.isFavorite);
        })
        .catch(() => {
          this.favoriteChanging = false;
        });
    },
    changeNotes() {
      this.notesChanging = true;
      this.$apollo
        .mutate({
          mutation: changeArtGuideEventNotesMutation,
          variables: {
            id: this.event.id,
            notes: this.notes,
          },
        })
        .then(({ data }) => {
          this.notesChanging = false;

          if (!data.artGuideEvent) {
            this.notes = this.event.notes;
            return;
          }

          this.notes = data.artGuideEvent.notes;
          this.$emit('notes-changed', this.event.position, this.notes);
        })
        .catch(() => {
          this.notesChanging = false;
          this.notes = this.event.notes;
        });
    },
    handleDetachEvent() {
      this.$emit('detach-event', this.event.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide__events-group-item {
  display: flex;

  &:not(:first-child) {
    margin-top: 70px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  &-image {
    width: 440px;
    height: 280px;

    .art-guide__image {
      &-wrapper {
        width: 100%;
        height: 100%;
      }

      width: 100%;
      height: 100%;
    }

    @media (max-width: 1199px) {
      width: 400px;
      height: 270px;
    }
    @media (max-width: 991px) {
      width: 280px;
      height: 200px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &-body {
    flex: 1;
    padding-left: 40px;
    padding-right: 80px;

    @media (max-width: 1199px) {
      padding-right: 50px;
    }
    @media (max-width: 991px) {
      padding-left: 35px;
      padding-right: 15px;
    }
    @media (max-width: 767px) {
      padding-left: 0;
    }

    .app-section-view-toggle {
      padding: 16px 0;
    }
  }

  &-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;

    &-link {
      color: inherit;
    }

    &-link:hover & {
      color: #555;
    }
  }

  .opening-hours {
    &-season {
      &:not(:first-child) {
        margin-top: 15px;
      }

      &__title {
        margin-bottom: 5px;
        font-weight: 500;
      }
    }
  }
}
</style>
